import SecureIcon from "@components/svg/secureIcon";
import React, { ReactElement } from "react";
import { footerMenu } from "src/lib/vpnpricecomparison/menu";
import colors from "@styles/variables/vpnpricecomparisonVariables.module.scss";

export default function Footer(): ReactElement {
    const copyright = `© ${new Date().getFullYear()} VPNPriceComparison. All Rights Reserved`;
    const bestVpn = `Best VPNs Services in ${new Date().getFullYear()}`;
    return (
        <footer className="py-5 pb-10 lg:pb-14 text-center px-4 max-w-7xl mx-auto mt-10">
            <div
                style={{ borderColor: colors.paleGrey }}
                className="inner lg:flex lg:justify-between pt-6  border-t-2"
            >
                <div className="lg:w-5/12">
                    <h2 className="text-xl font-bold lg:hidden">{bestVpn}</h2>
                    <p className="text-sm font-light mt-2 lg:text-left w-11/12 mx-auto text-vpn-comparison-slightly-black lg:hidden">
                        We are committed to providing honest reviews and advice
                        about VPN services to help protect your online privacy
                        and security.Since 2016, we’ve reviewed 69 VPN services
                        and published 4,515 real user reviews.
                    </p>
                    <p className="text-sm font-medium mt-5 lg:text-left">
                        Granted Security with SSL Certificate.
                    </p>
                    <div className="flex flex-row justify-center items-center mt-2 lg:justify-start font-medium">
                        <SecureIcon />
                        <span className="ml-3 text-sm font-light">
                            <strong className="font-medium">SECURE</strong> SSL
                            ENCRYPTION
                        </span>
                    </div>
                </div>
                <div className="mt-7 lg:mb-0 lg:flex lg:flex-col lg:justify-between">
                    <nav>
                        <ul className="flex justify-center">
                            {footerMenu.map((menuItem, index) => (
                                <li
                                    key={index}
                                    className="px-3 text-vpn-comparison-slightly-black font-light"
                                >
                                    <a
                                        className="hover:text-vpn-comparison-melon"
                                        href={menuItem.href}
                                    >
                                        {menuItem.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="copyright text-xs font-light mt-3 lg:mt-0">
                        <span>{copyright}</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
